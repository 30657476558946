import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import { Box, List, CardMedia, Typography, ListItem, ListItemButton, ListItemIcon, ListItemText, Icon } from "@mui/material";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DownloadIcon from "@mui/icons-material/Download";

import { userState, IMenuItem } from "../interface/MainInterface";

interface propsType {
  userState: userState;
  drawerWidth: number;
  largeDrawerWidth: number;
  selectedMenu: string;
  move: any;
}

// 공통 메뉴 : 일반사용자 접근
export const MENU: IMenuItem[] = [
  {
    name: "라이브",
    iconType: "mui-icon",
    icon: <VideoLibraryIcon sx={{ width: "18px", height: "18px", color: "#aec5fe" }} />,
    path: "/list",
    linkType: "link",
  },
  {
    name: "방송별통계",
    iconType: "mui-icon",
    icon: <EqualizerIcon sx={{ width: "18px", height: "18px", color: "#aec5fe" }} />,
    path: "/totalStatistic",
    linkType: "link",
  },
  {
    name: "이용가이드",
    iconType: "mui-icon",
    icon: <MenuBookIcon sx={{ width: "18px", height: "18px", color: "#aec5fe" }} />,
    path: "/broadcastGuide",
    linkType: "open",
  },
  {
    name: "송출App다운로드",
    iconType: "mui-icon",
    icon: <DownloadIcon sx={{ width: "18px", height: "18px", color: "#aec5fe" }} />,
    path: "/broadcastDownload",
    linkType: "open",
  },
  {
    name: "마이페이지",
    iconType: "img",
    icon: "/images/icon-mypage.png",
    path: "/setting",
    linkType: "link",
  },
];

export const ADMIN_MENU: IMenuItem[] = [
  {
    name: "사용자 관리",
    iconType: "mui-icon",
    icon: <ManageAccountsIcon sx={{ width: "16px", height: "16px", color: "#aec5fe" }} />,
    path: "/mngUser",
    linkType: "link",
  },
  {
    name: "오류 모니터",
    iconType: "mui-icon",
    icon: <NotificationImportantIcon sx={{ width: "16px", height: "16px", color: "#aec5fe" }} />,
    path: "/errorMonitor",
    linkType: "link",
  },
];

const LeftMenu = (props: propsType, ref: any) => {
  const [expandMenu, setExpandMenu] = useState<any>([]); // 자식메뉴 있을때

  // const uploadButton = `<iframe src="https://thd-play.livehyundai.com/ebdUploadVideo?id=${props.userState.id}&videoKey=&txtBtn=iframe uplaod" frameborder="0" style="width:100%;height:37px;" ></iframe>`;

  useEffect(() => {
    return () => {
      setExpandMenu([]);
    };
  }, []);

  useEffect(() => {
    // 메뉴 변경시 하일라이트를 위한 State 계산
    for (const menu of MENU) {
      if (menu.children) {
        for (const child of menu.children) {
          if (props.selectedMenu.split("/")[1] === child.path.split("/")[1]) {
            let temp = { ...expandMenu };
            temp[menu.path] = true;
            setExpandMenu(temp);
          }
        }
      }
    }
  }, [props.selectedMenu, expandMenu]);

  // 사이트 로고 동적 할당
  function SiteLogo() {
    return (
      <h1 className="logo-root">
        <a href="/" className="logo-a">
          <img className="logo-img" src="/images/logo.png" alt="logo" />
        </a>
      </h1>
    );
  }

  const ListMenu = () => {
    return (
      <>
        <Box className="left-menu-root">
          <Typography className="logo-menu-title">MENU</Typography>
          <List key={"left-menu"} sx={{ p: 0 }}>
            {MENU.map((menuInfo, index) =>
              menuInfo.adminOnly && !props.userState.isAdmin ? (
                <div key={menuInfo.name + "" + index}></div>
              ) : (
                <div key={menuInfo.name + "" + index}>
                  <ListItem
                    disablePadding
                    sx={{
                      height: "42px",
                      width: "218px",
                      p: 0,
                    }}
                  >
                    <ListItemButton
                      className="left-menu-wrapper"
                      selected={menuInfo.path === props.selectedMenu}
                      onClick={() => {
                        props.move(menuInfo);
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "24px" }}>
                        {menuInfo.iconType === "img" && <CardMedia component="img" className="left-menu-icon" image={menuInfo.icon} />}
                        {menuInfo.iconType === "mui-icon" && <Icon sx={{ fontSize: "16px" }}>{menuInfo.icon}</Icon>}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          props.drawerWidth === props.largeDrawerWidth && (
                            <Typography className={"left-menu-text"}>{menuInfo.name}</Typography>
                          )
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </div>
              )
            )}
          </List>
        </Box>
      </>
    );
  };

  const AdminMenu = () => {
    return (
      <>
        <Box className="left-menu-root mb-20">
          <Typography className="logo-menu-title">ADMIN</Typography>
          <List key={"left-menu"} sx={{ p: 0 }}>
            {ADMIN_MENU.map((menuInfo, index) =>
              menuInfo.adminOnly && !props.userState.isSuperAdmin ? (
                <div key={menuInfo.name + "" + index}></div>
              ) : (
                <div key={menuInfo.name + "" + index}>
                  <ListItem
                    disablePadding
                    sx={{
                      height: "42px",
                      width: "218px",
                      p: 0,
                    }}
                  >
                    <ListItemButton
                      className="left-menu-wrapper"
                      selected={menuInfo.path === props.selectedMenu}
                      onClick={() => {
                        props.move(menuInfo);
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "24px" }}>
                        {menuInfo.iconType === "img" && <CardMedia component="img" className="left-menu-icon" image={menuInfo.icon} />}
                        {menuInfo.iconType === "mui-icon" && <Icon sx={{ fontSize: "16px" }}>{menuInfo.icon}</Icon>}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          props.drawerWidth === props.largeDrawerWidth && (
                            <Typography className={"left-menu-text"}>{menuInfo.name}</Typography>
                          )
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </div>
              )
            )}
          </List>
        </Box>
      </>
    );
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({}));

  return (
    <>
      <SiteLogo />
      <ListMenu />
      <Box sx={{ flexGrow: "1" }}></Box>
      {props.userState.isSuperAdmin && <AdminMenu />}
    </>
  );
};

export default forwardRef(LeftMenu);
